<template>
  <b-sidebar
    id="serv-ob-nt-detail"
    sidebar-class="sidebar-xl"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
  
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0 font-weight-bolder">
          장애 현황 파악 상세
        </h4>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Header End -->
      <!-- Body -->
      <b-form
        @submit.prevent
      >
            <b-table
            ref="refInvoiceListTable"
            :items="actiSettingCeItem"
            responsive
            :fields="actiSettingColums"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="조회된 목록이 없습니다."
            :sort-desc.sync="isSortDirDesc"
            class="custom position-relative table-setting text-left mb-0"
            >
            </b-table>
			      <b-table
            :items="actiSettingCeItem2"
            responsive
            :fields="actiSettingColums2"
            primary-key="id"
            show-empty
            empty-text="조회된 목록이 없습니다."
            :sort-desc.sync="isSortDirDesc" 
            class="custom position-relative table-setting text-left mb-0"
            >
            </b-table>
			      <b-table
            :items="actiSettingCeItem3"
            responsive
            :fields="actiSettingColums3"
            primary-key="id"
            show-empty
            empty-text="조회된 목록이 없습니다."
            :sort-desc.sync="isSortDirDesc"
            class="custom position-relative table-setting text-left mb-0"
            >
            </b-table>
      </b-form>
      <!-- Body End -->
    </template>
  </b-sidebar>
</template>
<style>
	/* .b-sidebar.sidebar-xl{
		width: 56rem !important;
	} */
	.custom.table-setting th div{
		padding-left: .9rem !important;
		text-align: left;
	}
	.custom.table-setting table thead th:first-child{
		width: 29%;
	}
	.custom.table-setting table thead th{
		padding: 0;
	}
	.custom.table-setting table tbody td{
		padding-left: 1.5rem !important;
		text-align: left;
	}
</style>
<script>
import {
  BSidebar, BForm, BTable, VBToggle,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar, BForm, BTable,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    detailItems: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      data: {
      },
      actiSettingCeItem: [
        { 
            'ce_ver': 'Severity',
            'commend': '',
        },
        {
          'ce_ver': 'Last Transition',
          'commend': '',
        }
      ],
      actiSettingCeItem3: [
        { 
            'ce_ver': 'Affected object',
            'commend': '',
        },
        { 
            'ce_ver': 'Description',
            'commend': '',
        },
        {
          'ce_ver': 'ID',
          'commend': '',
        },
        {
          'ce_ver': 'Type',
          'commend': '',
        },
        {
          'ce_ver': 'Cause',
          'commend': '',
        },
        {
          'ce_ver': 'Created at',
          'commend': '',
        },
        {
          'ce_ver': 'Code',
          'commend': '',
        },
        {
          'ce_ver': 'Number of Occurrences',
          'commend': '',
        },
        {
          'ce_ver': 'Original severity',
          'commend': '',
        },
        {
          'ce_ver': 'Previous severity',
          'commend': '',
        },
        {
          'ce_ver': 'Highest severity',
          'commend': '',
        },
      ],

    }
  },
  watch:{
    detailItems(data){
      this.actiSettingCeItem = [
        { 
            'ce_ver': 'Severity',
            'commend': data.severity,
        },
        {
          'ce_ver': 'Last Transition',
          'commend': data.lastTransition,
        }
      ]
      this.actiSettingCeItem3 = [
        { 
            'ce_ver': 'Affected object',
            'commend': data.dn,
        },
        { 
            'ce_ver': 'Description',
            'commend': data.descr,
        },
        {
          'ce_ver': 'ID',
          'commend': data.id,
        },
        {
          'ce_ver': 'Type',
          'commend': data.type,
        },
        {
          'ce_ver': 'Cause',
          'commend': data.cause,
        },
        {
          'ce_ver': 'Created at',
          'commend': data.created,
        },
        {
          'ce_ver': 'Code',
          'commend': data.code,
        },
        {
          'ce_ver': 'Number of Occurrences',
          'commend': data.occur,
        },
        {
          'ce_ver': 'Original severity',
          'commend': data.origSeverity,
        },
        {
          'ce_ver': 'Previous severity',
          'commend': data.prevSeverity,
        },
        {
          'ce_ver': 'Highest severity',
          'commend': data.highestSeverity,
        },
      ]
    }
  },
  setup() {
    const refInvoiceListTable = ref(null)
    const perPage = ref(10)
    const totalInvoices = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const actiSettingColums = [
        { key: 'ce_ver', label: 'Summary'},
        { key: 'commend', label: ''},
    ]
  
	  const actiSettingColums2 = [
        { key: 'ce_ver', label: 'Actions'},
        { key: 'commend', label: ''},
    ]
    const actiSettingCeItem2 = [
        { 
            'ce_ver': 'Actions',
            'commend': 'no',
        },
    ]

	const actiSettingColums3 = [
        { key: 'ce_ver', label: 'Properties'},
        { key: 'commend', label: ''},
    ]
  
    const resolveInvoiceStatusVariantAndIcon = status => {
        if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
        if (status === 'On') return { variant: 'success', icon: 'CheckCircleIcon' }
        if (status === 'On3') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
        if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
        if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
        if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
        if (status === 'On2') return { variant: 'primary', icon: 'CheckCircleIcon' }
        if (status === '') return { variant: 'danger', icon: 'InfoIcon' }
        return { variant: 'secondary', icon: 'XIcon' }
    }
    const resolveClientAvatarVariant = status => {
        if (status === '예약일자') return 'primary'
        if (status === '예약시작') return 'danger'
        if (status === '예약종료') return 'secondary'
        if (status === 'Draft') return 'warning'
        if (status === 'Sent') return 'info'
        if (status === 'Past Due') return 'success'
        return 'primary'
    }
    

    return {
      perPage,
      perPageOptions,
      actiSettingColums,
	  actiSettingColums2,
	  actiSettingCeItem2,
	  actiSettingColums3,
      sortBy,
      isSortDirDesc,
      totalInvoices,
      currentPage,
      refInvoiceListTable,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
  methods: {
    setSetting(c) {
        if(window.confirm('초기값으로 설정하시겠습니까?')){
            return c()
        }
        return false
    }
  },
}
</script>

<style>

</style>
