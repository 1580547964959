<template>
  <section id="dashboard-main" class="mt-2">
    <b-row class="match-height">
        <!-- 장애 현황 파악 리스트 -->
        <b-col
        xl="12"
        lg="12"
        md="12"
        class="ecs-card"
        >
          <serv-ob-nt-list :active="this.active"></serv-ob-nt-list>
        </b-col>
        <!-- 장애 현황 파악 END -->
    </b-row>
  </section>
</template>
<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import ServObNtList from './components/ServObNtList.vue'

export default {
  components: {
    BRow,
    BCol,
    ServObNtList,
  },
  data() {
    return {
      data: {},
      active: true,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scope>
th,
td{
    text-align: center;
    white-space: nowrap;
}
</style>
